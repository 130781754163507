import './App.css';

function App() {
  return (
    <div class="app">
      <b></b>
      <h1>RODGER BOYCE</h1>
      <b></b>
      <h2>Website currently under development...</h2>
    </div>
  );
}

export default App;
